'use strict';

/**
 * @ngdoc overview
 * @name dialengaExeviBackoffice
 * @description
 * # dialengaExeviBackoffice
 *
 * Main module of the application.
 */
angular.module('dialengaExeviBackoffice', [
        'ngAnimate',
        'ngCookies',
        'ngResource',
        'ngRoute',
        'ngSanitize',
        'ui.bootstrap',
        'pascalprecht.translate',
        'tmh.dynamicLocale',
        'http-auth-interceptor',
        'ngFileUpload',
        'kendo.directives'
    ])
    .config(['$routeProvider', '$httpProvider', '$locationProvider', '$qProvider', 'tmhDynamicLocaleProvider', 'CONTENT_KIND', 'MODULES', 'USER_ROLES',
        function($routeProvider, $httpProvider, $locationProvider, $qProvider, tmhDynamicLocaleProvider, CONTENT_KIND, MODULES, USER_ROLES) {
            $routeProvider
                .when('/login', {
                    templateUrl: './ng1/components/login/login.html',
                    controller: 'LoginController',
                    title: 'login.title',
                    access: {
                        authorizedRoles: [USER_ROLES.all]
                    },
                    resolve: {
                        authorization: ['$location', '$q', 'AuthenticationService', function($location, $q, AuthenticationService) {
                            return AuthenticationService.getUserAuthenticationStatus().then(function(isUserAuthenticated) {
                                if (!isUserAuthenticated) {
                                    return $q.resolve();
                                } else {
                                    $location.search('c', null);
                                    throw new AlreadyLoggedError();
                                }
                            });
                        }],
                        companyLoginConfiguration: ['$route', 'CompaniesService', function($route, CompaniesService) {
                            if ($route.current.params && $route.current.params.c) {
                                localStorage.setItem('lastUserLoginBrand', $route.current.params.c);
                                return CompaniesService.getCompanyLoginConfiguration($route.current.params.c).then(function(companyLoginConfiguration) {
                                    return companyLoginConfiguration;
                                });
                            } else {
                                return null;
                            }
                        }]
                    }
                })
                .when('/reset-password', {
                    templateUrl: './ng1/components/reset-password/reset-password.html',
                    controller: 'ResetPasswordController',
                    title: 'login.form.resetpassword',
                    access: {
                        authorizedRoles: [USER_ROLES.all]
                    },
                    resolve: {
                        authorization: ['GlobalService', function(GlobalService) {
                            return GlobalService.redirectToDashboardIfUserIsLogged();
                        }]
                    }
                })
                .when('/email-sent', {
                    templateUrl: './ng1/components/email-sent/email-sent.html',
                    title: 'email-sent.title',
                    access: {
                        authorizedRoles: [USER_ROLES.all]
                    },
                    resolve: {
                        authorization: ['GlobalService', function(GlobalService) {
                            return GlobalService.redirectToDashboardIfUserIsLogged();
                        }]
                    }
                })
                .when('/activate-user', {
                    templateUrl: './ng1/components/update-password/update-password.html',
                    controller: 'UpdatePasswordController',
                    title: 'login.form.activateuser',
                    access: {
                        authorizedRoles: [USER_ROLES.all]
                    },
                    resolve: {
                        resolvedParams: ['$location', '$route', 'GlobalService', 'UsersService', 'defaultLanguage',
                            function($location, $route, GlobalService, UsersService, defaultLanguage) {
                                return GlobalService.redirectToDashboardIfUserIsLogged().then(function() {
                                    if (!$route.current.params.token) {
                                        $location.search({ 'token': null, 'lang': null });
                                        $location.path('/not-found').replace();
                                    }
                                    return {
                                        title: 'login.form.activateuser',
                                        saveButtonText: 'users.activate',
                                        submitAction: UsersService.activateUser,
                                        redirectionPath: '/activated-account',
                                        language: $route.current.params.lang || defaultLanguage,
                                        showPrivacyPolicyLink: true,
                                    };
                                }, function() {
                                    return false;
                                });
                            }
                        ]
                    }
                })
                .when('/activated-account', {
                    templateUrl: './ng1/components/password-updated/password-updated.html',
                    controller: 'PasswordUpdatedController',
                    title: 'login.form.activatedUser',
                    access: {
                        authorizedRoles: [USER_ROLES.all]
                    },
                    resolve: {
                        resolvedParams: ['GlobalService', function(GlobalService) {
                            return GlobalService.redirectToDashboardIfUserIsLogged().then(function() {
                                return { title: 'updatePassword.userActivated' };
                            }, function() {
                                return false;
                            });
                        }]
                    }
                })
                .when('/update-password', {
                    templateUrl: './ng1/components/update-password/update-password.html',
                    controller: 'UpdatePasswordController',
                    title: 'login.form.updatepassword',
                    access: {
                        authorizedRoles: [USER_ROLES.all]
                    },
                    resolve: {
                        resolvedParams: ['$location', '$route', 'GlobalService', 'UsersService', 'defaultLanguage',
                            function($location, $route, GlobalService, UsersService, defaultLanguage) {
                                return GlobalService.redirectToDashboardIfUserIsLogged().then(function() {
                                    if (!$route.current.params.token) {
                                        $location.search({ 'token': null, 'lang': null });
                                        $location.path('/not-found').replace();
                                    }
                                    return {
                                        title: 'login.form.updatepassword',
                                        saveButtonText: 'global.form.save',
                                        submitAction: UsersService.resetPassword,
                                        redirectionPath: '/password-updated',
                                        language: $route.current.params.lang || defaultLanguage
                                    };
                                }, function() {
                                    return false;
                                });
                            }
                        ]
                    }
                })
                .when('/password-updated', {
                    templateUrl: './ng1/components/password-updated/password-updated.html',
                    controller: 'PasswordUpdatedController',
                    title: 'login.form.passwordupdated',
                    access: {
                        authorizedRoles: [USER_ROLES.all]
                    },
                    resolve: {
                        resolvedParams: ['GlobalService', function(GlobalService) {
                            return GlobalService.redirectToDashboardIfUserIsLogged().then(function() {
                                return { title: 'login.form.passwordupdated' };
                            }, function() {
                                return false;
                            });
                        }]
                    }
                })
                .when('/', {
                    templateUrl: './ng1/components/home/home.html',
                    controller: 'HomeController',
                    title: 'home.welcome.title',
                    access: {
                        authorizedRoles: [USER_ROLES.admin, USER_ROLES.manager, USER_ROLES.publisher]
                    }
                })
                .when('/users', {
                    templateUrl: './ng1/components/users/users.html',
                    controller: 'UsersController',
                    title: 'users.title',
                    access: {
                        authorizedRoles: [
                            USER_ROLES.admin, USER_ROLES.publisher, USER_ROLES.ftrayResponsible,
                            USER_ROLES.documentsManager, USER_ROLES.platformAdmin, USER_ROLES.teamResponsible, 
                            USER_ROLES.humanResources, USER_ROLES.employeeManager
                        ]
                    }
                })
                .when('/users/import-users', {
                    templateUrl: './ng1/components/import-users/import-users.html',
                    controller: 'ImportUsersController',
                    title: 'usersImport.title',
                    access: {
                        authorizedRoles: [USER_ROLES.admin, USER_ROLES.employeeManager]
                    }
                })
                .when('/user/create', {
                    templateUrl: './ng1/components/user/user-creation.html',
                    controller: 'UserProfileController',
                    controllerAs: 'vm',
                    title: 'users.newuser',
                    access: {
                        authorizedRoles: [USER_ROLES.admin, USER_ROLES.employeeManager]
                    }
                })
                .when('/user/:tabName/update/:id', {
                    templateUrl: './ng1/components/user/user.html',
                    controller: 'UserController',
                    controllerAs: 'vm',
                    access: {
                        authorizedRoles: [USER_ROLES.admin, USER_ROLES.humanResources, USER_ROLES.employeeManager]
                    },
                    resolve: {
                        selectedTab: ['$location', '$route', 'USER_TABS', function($location, $route, USER_TABS) {
                            if ($route.current.params && $route.current.params.tabName) {
                                if ($route.current.params.tabName === 'personaldata') {
                                    $route.current.$$route.title = 'users.tabs.profile';
                                    return USER_TABS.PROFILE;
                                } else if ($route.current.params.tabName === 'companydata') {
                                    $route.current.$$route.title = 'users.tabs.company';
                                    return USER_TABS.COMPANY;
                                } else if ($route.current.params.tabName === 'holidays') {
                                    $route.current.$$route.title = 'users.tabs.holidays';
                                    return USER_TABS.HOLIDAYS;
                                } else if ($route.current.params.tabName === 'documents') {
                                    $route.current.$$route.title = 'users.tabs.documents';
                                    return USER_TABS.DOCUMENTS;
                                } else if ($route.current.params.tabName === 'access') {
                                    $route.current.$$route.title = 'users.tabs.access';
                                    return USER_TABS.ACCESS;
                                } else if ($route.current.params.tabName === 'notes') {
                                    $route.current.$$route.title = 'users.tabs.notes';
                                    return USER_TABS.NOTES;
                                } else {
                                    $location.path('/not-found').replace();
                                }
                            } else {
                                $location.path('/not-found').replace();
                            }
                        }]
                    },
                    reloadOnUrl: false
                })
                .when('/profile', {
                    templateUrl: './ng1/components/profile/profile.html',
                    controller: 'ProfileController',
                    controllerAs: 'vm',
                    title: 'users.profile.title',
                    access: {
                        authorizedRoles: [
                            USER_ROLES.admin, USER_ROLES.manager, USER_ROLES.publisher, USER_ROLES.ftrayResponsible,
                            USER_ROLES.documentsManager, USER_ROLES.platformAdmin, USER_ROLES.teamResponsible, 
                            USER_ROLES.humanResources, , USER_ROLES.employeeManager
                        ]
                    }
                })
                .when('/audit', {
                    templateUrl: './ng1/components/audit/audit.html',
                    controller: 'AuditController',
                    title: 'audit.title',
                    access: {
                        authorizedRoles: [USER_ROLES.admin]
                    }
                })
                .when('/messages', {
                    templateUrl: './ng1/components/messages/messages.html',
                    controller: 'MessagesController',
                    title: 'global.navbar.messages',
                    access: {
                        authorizedRoles: [USER_ROLES.admin, USER_ROLES.manager, USER_ROLES.publisher]
                    }
                })
                .when('/messages/create', {
                    templateUrl: './ng1/components/message/message-create.html',
                    controller: 'MessageCreateController',
                    title: 'messages.stepper.titles.step1',
                    access: {
                        authorizedRoles: [USER_ROLES.admin, USER_ROLES.manager, USER_ROLES.publisher]
                    }
                })
                .when('/messages/:tabName/update/:id', {
                    templateUrl: './ng1/components/message/message.html',
                    controller: 'MessageController',
                    access: {
                        authorizedRoles: [USER_ROLES.admin, USER_ROLES.manager, USER_ROLES.publisher]
                    },
                    resolve: {
                        selectedTab: ['$location', '$route', 'MESSAGE_TABS', function($location, $route, MESSAGE_TABS) {
                            if ($route.current.params && $route.current.params.tabName) {
                                if ($route.current.params.tabName === 'content') {
                                    $route.current.$$route.title = 'messages.stepper.titles.step1';
                                    return MESSAGE_TABS.CONTENT;
                                } else if ($route.current.params.tabName === 'recipients') {
                                    $route.current.$$route.title = 'messages.stepper.titles.step2';
                                    return MESSAGE_TABS.RECIPIENTS;
                                } else if ($route.current.params.tabName === 'preview') {
                                    $route.current.$$route.title = 'messages.stepper.titles.step3.1';
                                    return MESSAGE_TABS.SHIPMENT;
                                } else {
                                    $location.path('/not-found').replace();
                                }
                            } else {
                                $location.path('/not-found').replace();
                            }
                        }],
                        DataService: ['MessageService', function(MessageService) {
                            return MessageService;
                        }],
                        contentType: function() {
                            return CONTENT_KIND.MESSAGE;
                        }
                    },
                    reloadOnUrl: false
                })

                .when('/messages/view/:id', {
                    templateUrl: './ng1/components/publication-summary/publication-summary.html',
                    controller: 'PublicationSummaryController',
                    controllerAs: 'vm',
                    title: 'publication.archived.messageTitle',
                    access: {
                        authorizedRoles: [USER_ROLES.admin, USER_ROLES.manager, USER_ROLES.publisher]
                    },
                    resolve: {
                        DataService: ['MessageService', function(MessageService) {
                            return MessageService;
                        }],
                        breadcrumbParentInfo: function() {
                            return {
                                title: 'global.navbar.messages',
                                path: '/messages',
                                type: CONTENT_KIND.MESSAGE
                            }
                        }
                    }
                })
                .when('/messages/statistics/:id', {
                    templateUrl: './ng1/components/message-statistics/message-statistics.html',
                    controller: 'MessageStatisticsController',
                    title: 'statistics.messages.title',
                    access: {
                        authorizedRoles: [USER_ROLES.admin, USER_ROLES.manager, USER_ROLES.publisher]
                    }
                })
                .when('/settings/categories', {
                    templateUrl: './ng1/components/categories/categories.html',
                    title: 'categories.title',
                    access: {
                        authorizedRoles: [USER_ROLES.admin, USER_ROLES.publisher]
                    }
                })
                .when('/settings/categories/create', {
                    templateUrl: './ng1/components/category/category.html',
                    title: 'model.category.newCategory',
                    access: {
                        authorizedRoles: [USER_ROLES.admin, USER_ROLES.publisher]
                    }
                })
                .when('/settings/categories/update/:id', {
                    templateUrl: './ng1/components/category/category.html',
                    title: 'model.category.editCategory',
                    access: {
                        authorizedRoles: [USER_ROLES.admin, USER_ROLES.publisher]
                    }
                })
                .when('/questionnaires', {
                    templateUrl: './ng1/components/questionnaires/questionnaires.html',
                    controller: 'QuestionnairesController',
                    title: 'questionnaires.title',
                    access: {
                        authorizedRoles: [USER_ROLES.admin, USER_ROLES.manager, USER_ROLES.publisher]
                    }
                })
                .when('/questionnaires/edit/:id', {
                    templateUrl: './ng1/components/questionnaire/questionnaire.html',
                    controller: 'QuestionnaireController',
                    title: 'questionnaires.titlecontent',
                    access: {
                        authorizedRoles: [USER_ROLES.admin, USER_ROLES.manager, USER_ROLES.publisher]
                    }
                })
                .when('/questionnaires/recipients/:id', {
                    templateUrl: './ng1/components/publication-recipients/questionnaire-recipients.html',
                    controller: 'QuestionnaireRecipientsController',
                    title: 'messages.stepper.titles.step2',
                    access: {
                        authorizedRoles: [USER_ROLES.admin, USER_ROLES.manager, USER_ROLES.publisher]
                    },
                    resolve: {
                        previewURL: ['$route', function($route) {
                            return '/questionnaires/preview/' + $route.current.params.id;
                        }],
                        updateURL: ['$route', function($route) {
                            return '/questionnaires/edit/' + $route.current.params.id;
                        }],
                        DataService: ['QuestionnaireService', function(QuestionnaireService) {
                            return QuestionnaireService;
                        }],
                        breadcrumbParentInfo: function() {
                            return {
                                title: 'global.navbar.questionnaires',
                                subtitle: 'questionnaires.titlecontent',
                                path: '/questionnaires',
                                type: CONTENT_KIND.DIALOGUE
                            }
                        }
                    }
                })
                .when('/questionnaires/preview/:id', {
                    templateUrl: './ng1/components/publication-preview/questionnaire-preview.html',
                    controller: 'QuestionnairePreviewController',
                    title: 'questionnaires.stepper.titles.step3.1',
                    access: {
                        authorizedRoles: [USER_ROLES.admin, USER_ROLES.manager, USER_ROLES.publisher]
                    },
                    resolve: {
                        breadcrumbs: ['$route', function($route) {
                            return {
                                mainList: {
                                    url: '/questionnaires',
                                    text: 'global.navbar.questionnaires',
                                    statePath: '/questionnaires'
                                },
                                contentUpdate: {
                                    url: '/questionnaires/edit/' + $route.current.params.id,
                                    text: 'questionnaires.titlecontent'
                                },
                                recipients: {
                                    url: '/questionnaires/recipients/' + $route.current.params.id,
                                    text: 'messages.stepper.recipients',
                                    statePath: '/questionnaires/recipients/' + $route.current.params.id
                                }
                            };
                        }],
                        contentType: function() {
                            return CONTENT_KIND.DIALOGUE;
                        },
                        mainTitle: function() {
                            return {
                                nonPending: 'questionnaires.stepper.titles.step3.1',
                                pending: 'questionnaires.stepper.titles.step3.2'
                            };
                        },
                        DataService: ['QuestionnaireService', function(QuestionnaireService) {
                            return QuestionnaireService;
                        }]
                    }
                })
                .when('/questionnaires/:id/questions/:questionId?', {
                    templateUrl: './ng1/components/questionnaire-question/questionnaire-question.html',
                    controller: 'QuestionnaireQuestionController',
                    title: 'questionnaire.question.questiontitle',
                    access: {
                        authorizedRoles: [USER_ROLES.admin, USER_ROLES.manager, USER_ROLES.publisher]
                    }
                })
                .when('/questionnaires/:id/endings/:questionId?', {
                    templateUrl: './ng1/components/questionnaire-ending/questionnaire-ending.html',
                    controller: 'QuestionnaireEndingController',
                    title: 'questionnaire.question.endingtitle',
                    access: {
                        authorizedRoles: [USER_ROLES.admin, USER_ROLES.manager, USER_ROLES.publisher]
                    }
                })
                .when('/questionnaires/statistics/:id', {
                    templateUrl: './ng1/components/questionnaire-statistics/questionnaire-statistics.html',
                    controller: 'QuestionnaireStatisticsController',
                    title: 'statistics.messages.title',
                    access: {
                        authorizedRoles: [USER_ROLES.admin, USER_ROLES.manager, USER_ROLES.publisher]
                    }
                })
                .when('/questionnaires/view/:id', {
                    templateUrl: './ng1/components/questionnaire-summary/questionnaire-summary.html',
                    controller: 'QuestionnaireSummaryController',
                    controllerAs: 'vm',
                    title: 'publication.archived.questionnaireTitle',
                    access: {
                        authorizedRoles: [USER_ROLES.admin, USER_ROLES.manager, USER_ROLES.publisher]
                    }
                })
                .when('/settings/users-groups', {
                    templateUrl: './ng1/components/segments/user-segments.html',
                    title: 'segments.title',
                    access: {
                        authorizedRoles: [USER_ROLES.admin, USER_ROLES.publisher, USER_ROLES.employeeManager]
                    }
                })
                .when('/settings/users-groups/update/:id', {
                    templateUrl: './ng1/components/segment/segment.html',
                    title: 'model.segment.editGroup',
                    access: {
                        authorizedRoles: [USER_ROLES.admin, USER_ROLES.publisher, USER_ROLES.employeeManager]
                    }
                })
                .when('/knowledge-center', {
                    templateUrl: './ng1/components/knowledge-center/knowledge-center.html',
                    controller: 'KnowledgeCenterController',
                    title: 'knowledgecenter.title',
                    module: MODULES.KNOWLEDGE_CENTER,
                    access: {
                        authorizedRoles: [USER_ROLES.admin, USER_ROLES.manager, USER_ROLES.publisher]
                    }
                })
                .when('/knowledge-center/create', {
                    templateUrl: './ng1/components/message/message-create.html',
                    controller: 'MessageCreateController',
                    title: 'knowledgecenter.newpilltitle',
                    module: MODULES.KNOWLEDGE_CENTER,
                    access: {
                        authorizedRoles: [USER_ROLES.admin, USER_ROLES.manager, USER_ROLES.publisher]
                    }
                })
                .when('/knowledge-center/:tabName/update/:id', {
                    templateUrl: './ng1/components/message/message.html',
                    controller: 'MessageController',
                    module: MODULES.KNOWLEDGE_CENTER,
                    access: {
                        authorizedRoles: [USER_ROLES.admin, USER_ROLES.manager, USER_ROLES.publisher]
                    },
                    resolve: {
                        selectedTab: ['$location', '$route', 'MESSAGE_TABS', function($location, $route, MESSAGE_TABS) {
                            if ($route.current.params && $route.current.params.tabName) {
                                if ($route.current.params.tabName === 'content') {
                                    $route.current.$$route.title = 'knowledgecenter.editpilltitle';
                                    return MESSAGE_TABS.CONTENT;
                                } else if ($route.current.params.tabName === 'recipients') {
                                    $route.current.$$route.title = 'knowledgecenter.stepper.titles.step2';
                                    return MESSAGE_TABS.RECIPIENTS;
                                } else if ($route.current.params.tabName === 'preview') {
                                    $route.current.$$route.title = 'knowledgecenter.stepper.titles.step3.1';
                                    return MESSAGE_TABS.SHIPMENT;
                                } else {
                                    $location.path('/not-found').replace();
                                }
                            } else {
                                $location.path('/not-found').replace();
                            }
                        }],
                        DataService: ['KnowledgeCenterService', function(KnowledgeCenterService) {
                            return KnowledgeCenterService;
                        }],
                        contentType: function() {
                            return CONTENT_KIND.KNOWLEDGE_PILL;
                        }
                    },
                    reloadOnUrl: false
                })
                .when('/knowledge-center/view/:id', {
                    templateUrl: './ng1/components/publication-summary/publication-summary.html',
                    controller: 'PublicationSummaryController',
                    controllerAs: 'vm',
                    title: 'publication.archived.knowledgeCenterTitle',
                    module: MODULES.KNOWLEDGE_CENTER,
                    access: {
                        authorizedRoles: [USER_ROLES.admin, USER_ROLES.manager, USER_ROLES.publisher]
                    },
                    resolve: {
                        DataService: ['KnowledgeCenterService', function(KnowledgeCenterService) {
                            return KnowledgeCenterService;
                        }],
                        breadcrumbParentInfo: function() {
                            return {
                                title: 'global.navbar.knowledgecenter',
                                path: '/knowledge-center',
                                type: CONTENT_KIND.KNOWLEDGE_PILL
                            }
                        }
                    }
                })
                .when('/knowledge-center/statistics/:id', {
                    templateUrl: './ng1/components/message-statistics/message-statistics.html',
                    controller: 'MessageStatisticsController',
                    title: 'statistics.messages.title',
                    module: MODULES.KNOWLEDGE_CENTER,
                    access: {
                        authorizedRoles: [USER_ROLES.admin, USER_ROLES.manager, USER_ROLES.publisher]
                    }
                })
                .when('/globalstatistics', {
                    templateUrl: './ng1/components/statistics/statistics.html',
                    controller: 'StatisticsController',
                    title: 'statistics.global.title',
                    access: {
                        authorizedRoles: [USER_ROLES.admin]
                    }
                })
                .when('/experiences', {
                    templateUrl: './ng1/components/experiences/experiences.html',
                    controller: 'ExperiencesController',
                    title: 'experiences.title',
                    module: MODULES.EXPERIENCES,
                    access: {
                        authorizedRoles: [USER_ROLES.admin]
                    }
                })
                .when('/requests', {
                    templateUrl: './ng1/components/conversations/requests.html',
                    title: 'conversations.title',
                    module: MODULES.CONVERSATIONS,
                    access: {
                        authorizedRoles: [USER_ROLES.admin, USER_ROLES.ftrayResponsible]
                    }
                })
                .when('/settings/conversations/:tabName', {
                    templateUrl: './ng1/components/conversations-settings/conversations-settings.html',
                    controller: 'ConversationsSettingsController',
                    controllerAs: 'vm',
                    module: MODULES.CONVERSATIONS,
                    access: {
                        authorizedRoles: [USER_ROLES.admin, USER_ROLES.ftrayManager]
                    },
                    resolve: {
                        selectedTab: ['$location', '$route', 'CONVERSATIONS_SETTINGS_TABS', function($location, $route, CONVERSATIONS_SETTINGS_TABS) {
                            if ($route.current.params && $route.current.params.tabName) {
                                if ($route.current.params.tabName === 'motives') {
                                    $route.current.$$route.title = 'motives.title';
                                    return CONVERSATIONS_SETTINGS_TABS.MOTIVES;
                                } else if ($route.current.params.tabName === 'feedback-trays') {
                                    $route.current.$$route.title = 'feedbackTrays.title';
                                    return CONVERSATIONS_SETTINGS_TABS.FEEDBACK_TRAYS;
                                } else {
                                    $location.path('/not-found').replace();
                                }
                            } else {
                                $location.path('/not-found').replace();
                            }
                        }]
                    },
                    reloadOnUrl: false
                })
                .when('/settings/conversations/feedback-trays/create', {
                    templateUrl: './ng1/components/feedback-tray/feedback-tray.html',
                    controller: 'FeedbackTrayController',
                    title: 'feedbackTray.tabs.feedbackTrays',
                    module: MODULES.CONVERSATIONS,
                    access: {
                        authorizedRoles: [USER_ROLES.admin, USER_ROLES.ftrayManager]
                    },
                    resolve: {
                        selectedTab: ['FEEDBACKTRAY_TABS', function(FEEDBACKTRAY_TABS) {
                            return FEEDBACKTRAY_TABS.TRAY;
                        }]
                    }
                })
                .when('/settings/conversations/feedback-trays/:tabName/:id', {
                    templateUrl: './ng1/components/feedback-tray/feedback-tray.html',
                    controller: 'FeedbackTrayController',
                    title: 'feedbackTray.tabs.feedbackTrays',
                    module: MODULES.CONVERSATIONS,
                    access: {
                        authorizedRoles: [USER_ROLES.admin, USER_ROLES.ftrayManager]
                    },
                    resolve: {
                        selectedTab: ['$location', '$route', 'FEEDBACKTRAY_TABS', function($location, $route, FEEDBACKTRAY_TABS) {
                            if ($route.current.params && $route.current.params.tabName) {
                                if ($route.current.params.tabName === 'update') {
                                    $route.current.$$route.title = 'feedbackTray.tabs.feedbackTrays';
                                    return FEEDBACKTRAY_TABS.TRAY;
                                } else if ($route.current.params.tabName === 'users') {
                                    $route.current.$$route.title = 'feedbackTray.tabs.usersAndGroups';
                                    return FEEDBACKTRAY_TABS.USERS_AND_GROUPS;
                                } else if ($route.current.params.tabName === 'questionnaire') {
                                    $route.current.$$route.title = 'feedbackTray.tabs.questionnaire';
                                    return FEEDBACKTRAY_TABS.QUESTIONNAIRE;
                                } else {
                                    $location.path('/not-found').replace();
                                }
                            } else {
                                $location.path('/not-found').replace();
                            }
                        }]
                    },
                    reloadOnUrl: false
                })
                .when('/settings/conversations/feedback-trays/questionnaire/update/:id', {
                    templateUrl: './ng1/components/feedback-tray/feedback-tray-questionnaire-update.html',
                    controller: 'FeedbackTrayQuestionnaireUpdateController',
                    title: 'questionnaire.question.questiontitle',
                    access: {
                        authorizedRoles: [USER_ROLES.admin, USER_ROLES.ftrayManager]
                    }
                })
                .when('/settings/conversations/feedback-trays/questionnaire/:id/questions/:questionId?', {
                    templateUrl: './ng1/components/feedback-tray/feedback-tray-questionnaire-question.html',
                    controller: 'FeedbackTrayQuestionnaireQuestionController',
                    title: 'questionnaire.question.questiontitle',
                    access: {
                        authorizedRoles: [USER_ROLES.admin, USER_ROLES.ftrayManager]
                    }
                })
                .when('/settings/conversations/feedback-trays/questionnaire/:id/endings/:questionId?', {
                    templateUrl: './ng1/components/feedback-tray/feedback-tray-questionnaire-question.html',
                    controller: 'FeedbackTrayQuestionnaireQuestionController',
                    title: 'questionnaire.question.endingtitle',
                    access: {
                        authorizedRoles: [USER_ROLES.admin, USER_ROLES.ftrayManager]
                    }
                })
                .when('/time-tracking', {
                    templateUrl: './ng1/components/time-tracking/time-tracking-list.html',
                    title: 'timeTracking.featureTitle',
                    module: MODULES.TIME_TRACKING,
                    access: {
                        authorizedRoles: [USER_ROLES.admin, USER_ROLES.humanResources]
                    }
                })
                .when('/time-tracking/:id/detail', {
                    templateUrl: './ng1/components/time-tracking/time-tracking-list-detail.html',
                    title: 'timeTracking.featureTitle',
                    module: MODULES.TIME_TRACKING,
                    access: {
                        authorizedRoles: [USER_ROLES.admin, USER_ROLES.humanResources]
                    }
                })
                .when('/documents/:tabName?', {
                    templateUrl: './ng1/components/documents/documents.html',
                    title: 'documents.title',
                    module: MODULES.DOCUMENTS,
                    access: {
                        authorizedRoles: [USER_ROLES.admin, USER_ROLES.documentsManager]
                    },
                    resolve: {
                        selectedTab: ['$location', '$route', 'DOCUMENTS_TABS', function($location, $route, DOCUMENTS_TABS) {
                            if ($route.current.params && $route.current.params.tabName) {
                                if ($route.current.params.tabName === 'remote-signature') {
                                    $route.current.$$route.title = 'remoteSignature.title';
                                    return DOCUMENTS_TABS.REMOTE_SIGNATURE;
                                } else {
                                    $location.path('/not-found').replace();
                                }
                            } else {
                                $location.path('/documents').replace();
                                $route.current.$$route.title = 'documents.title';
                                return DOCUMENTS_TABS.ALL_DOCUMENTS;
                            }
                        }]
                    },
                    reloadOnUrl: false
                })
                .when('/import-documents', {
                    templateUrl: './ng1/components/documents/import-documents.html',
                    title: 'documents.import.title',
                    module: MODULES.DOCUMENTS,
                    access: {
                        authorizedRoles: [USER_ROLES.admin, USER_ROLES.documentsManager]
                    }
                })
                .when('/import-documents/history', {
                    templateUrl: './ng1/components/documents-history/documents-history.html',
                    title: 'documents.history.title',
                    module: MODULES.DOCUMENTS,
                    access: {
                        authorizedRoles: [USER_ROLES.admin, USER_ROLES.documentsManager]
                    }
                })
                .when('/import-documents/history/:id', {
                    templateUrl: './ng1/components/documents/document-transaction-detail.html',
                    title: 'documents.history.detail.title',
                    module: MODULES.DOCUMENTS,
                    access: {
                        authorizedRoles: [USER_ROLES.admin, USER_ROLES.documentsManager]
                    }
                })
                .when('/remote-signature/create', {
                    templateUrl: './ng1/components/remote-signature-creation/remote-signature-creation.html',
                    title: 'remoteSignature.creation.title',
                    module: MODULES.DOCUMENTS,
                    access: {
                        authorizedRoles: [USER_ROLES.admin, USER_ROLES.documentsManager]
                    }
                })
                .when('/remote-signature/detail/:id', {
                    templateUrl: './ng1/components/remote-signature-detail/remote-signature-detail.html',
                    title: 'remoteSignature.detail.title',
                    module: MODULES.DOCUMENTS,
                    access: {
                        authorizedRoles: [USER_ROLES.admin, USER_ROLES.documentsManager]
                    }
                })
                .when('/remote-signature/massive-detail/:id', {
                    templateUrl: './ng1/components/remote-signature-detail/remote-signature-massive-detail.html',
                    title: 'remoteSignature.detail.title',
                    module: MODULES.DOCUMENTS,
                    access: {
                        authorizedRoles: [USER_ROLES.admin, USER_ROLES.documentsManager]
                    }
                })
                .when('/timeoff/:tabName', {
                    templateUrl: './ng1/components/timeoff/timeoff.html',
                    controller: 'TimeoffController',
                    controllerAs: 'vm',
                    title: 'global.navbar.timeoffRequests',
                    module: MODULES.HOLIDAYS,
                    access: {
                        authorizedRoles: [USER_ROLES.admin, USER_ROLES.humanResources]
                    },
                    resolve: {
                        selectedTab: ['$location', '$route', 'TIMEOFF_TABS', function($location, $route, TIMEOFF_TABS) {
                            if ($route.current.params && $route.current.params.tabName) {
                                if ($route.current.params.tabName === 'requests') {
                                    $route.current.$$route.title = 'timeoff.tabs.requests';
                                    return TIMEOFF_TABS.REQUESTS;
                                } else {
                                    $location.path('/not-found').replace();
                                }
                            } else {
                                $location.path('/not-found').replace();
                            }
                        }]
                    }
                })
                .when('/global-settings', {
                    templateUrl: './ng1/components/global-settings/global-settings.html',
                    title: "global.navbar.globalSettings",
                    access: {
                        authorizedRoles: [USER_ROLES.humanResources, USER_ROLES.admin, USER_ROLES.publisher, USER_ROLES.employeeManager, USER_ROLES.ftrayManager, USER_ROLES.documentsManager, USER_ROLES.chatroomManager]
                    }
                })
                .when('/settings/my-company/:tabName', {
                    templateUrl: './ng1/components/my-company/my-company.html',
                    title: 'myCompany.title',
                    access: {
                        authorizedRoles: [USER_ROLES.admin, USER_ROLES.humanResources]
                    },
                    resolve: {
                        selectedTab: ['$location', '$route', 'MY_COMPANY_TABS', function($location, $route, MY_COMPANY_TABS) {
                            if ($route.current.params && $route.current.params.tabName) {
                                switch ($route.current.params.tabName) {
                                    case 'global':
                                        $route.current.$$route.title = 'myCompany.title';
                                        return MY_COMPANY_TABS.GLOBAL;
                                    case 'companies':
                                        $route.current.$$route.title = 'subsidiaries.title';
                                        return MY_COMPANY_TABS.COMPANIES;
                                    case 'workplaces':    
                                        $route.current.$$route.title = 'workplaces.title';
                                        return MY_COMPANY_TABS.WORKPLACES;
                                    case 'departments':    
                                        $route.current.$$route.title = 'departments.title';
                                        return MY_COMPANY_TABS.DEPARTMENTS;
                                    case 'teams':    
                                        $route.current.$$route.title = 'timeoff.teams.title';
                                        return MY_COMPANY_TABS.TEAMS;
                                    case 'external-links':    
                                        $route.current.$$route.title = 'externalLinks.title';
                                        return MY_COMPANY_TABS.EXTERNAL_LINKS;
                                    default:
                                        $location.path('/not-found').replace();
                                        break;
                                }
                            } else {
                                $location.path('/not-found').replace();
                            }
                        }]
                    },
                    reloadOnUrl: false
                })
                .when('/settings/my-company/company/:tabName/update/:id', {
                    templateUrl: './ng1/components/company/company-update.html',
                    title: 'company.title',
                    access: {
                        authorizedRoles: [USER_ROLES.admin]
                    },
                    resolve: {
                        selectedTab: ['$location', '$route', 'COMPANY_TABS', function($location, $route, COMPANY_TABS) {
                            if ($route.current.params && $route.current.params.tabName) {
                                if ($route.current.params.tabName === 'general') {
                                    return COMPANY_TABS.GENERAL;
                                } else if ($route.current.params.tabName === 'users') {
                                    return COMPANY_TABS.USERS;
                                } else {
                                    $location.path('/not-found').replace();
                                }
                            } else {
                                $location.path('/not-found').replace();
                            }
                        }]
                    },
                    reloadOnUrl: false
                })
                .when('/settings/my-company/workplace/:tabName/update/:id', {
                    templateUrl: './ng1/components/timeoff-settings-workplace/timeoff-settings-workplace.html',
                    controller: 'TimeoffSettingsWorkplaceController',
                    controllerAs: 'vm',
                    access: {
                        authorizedRoles: [USER_ROLES.humanResources, USER_ROLES.admin]
                    },
                    resolve: {
                        selectedTab: ['$location', '$route', 'TIMEOFF_SETTINGS_WORKPLACE_TABS', function($location, $route, TIMEOFF_SETTINGS_WORKPLACE_TABS) {
                            if ($route.current.params && $route.current.params.tabName) {
                                if ($route.current.params.tabName === 'location') {
                                    $route.current.$$route.title = 'workplaces.tabs.location';
                                    return TIMEOFF_SETTINGS_WORKPLACE_TABS.LOCATION;
                                } else if ($route.current.params.tabName === 'holidays') {
                                    $route.current.$$route.title = 'workplaces.tabs.holidays';
                                    return TIMEOFF_SETTINGS_WORKPLACE_TABS.HOLIDAYS;
                                } else if ($route.current.params.tabName === 'employees') {
                                    $route.current.$$route.title = 'workplaces.tabs.employees';
                                    return TIMEOFF_SETTINGS_WORKPLACE_TABS.EMPLOYEES;
                                } else {
                                    $location.path('/not-found').replace();
                                }
                            } else {
                                $location.path('/not-found').replace();
                            }
                        }]
                    },
                    reloadOnUrl: false
                })
                .when('/settings/my-company/teams/update/:id', {
                    templateUrl: './ng1/components/timeoff-settings-team/timeoff-settings-team.html',
                    controller: 'TimeoffSettingsTeamController',
                    controllerAs: 'vm',
                    title: "timeoff.teams.title",
                    access: {
                        authorizedRoles: [USER_ROLES.humanResources, USER_ROLES.admin]
                    }
                })
                .when('/settings/my-company/department/update/:id', {
                    templateUrl: './ng1/components/department/department-update.html',
                    title: "departments.title",
                    access: {
                        authorizedRoles: [USER_ROLES.admin]
                    }
                })
                .when('/settings/my-company/external-link/create', {
                    templateUrl: './ng1/components/external-link/external-link.html',
                    title: 'externalLinks.form.new',
                    access: {
                        authorizedRoles: [USER_ROLES.admin, USER_ROLES.manager, USER_ROLES.publisher]
                    }
                })
                .when('/settings/my-company/external-link/update/:id', {
                    templateUrl: './ng1/components/external-link/external-link.html',
                    title: 'externalLinks.form.edit',
                    access: {
                        authorizedRoles: [USER_ROLES.admin, USER_ROLES.manager, USER_ROLES.publisher]
                    }
                })
                .when('/settings/timeoff/:tabName', {
                    templateUrl: './ng1/components/timeoff-settings/timeoff-settings.html',
                    module: MODULES.HOLIDAYS,
                    access: {
                        authorizedRoles: [USER_ROLES.humanResources, USER_ROLES.admin]
                    },
                    resolve: {
                        selectedTab: ['$location', '$route', 'TIMEOFF_SETTINGS_TABS', function($location, $route, TIMEOFF_SETTINGS_TABS) {
                            if ($route.current.params && $route.current.params.tabName) {
                                if ($route.current.params.tabName === 'schemes') {
                                    $route.current.$$route.title = 'timeoffSettings.tabs.schemes';
                                    return TIMEOFF_SETTINGS_TABS.SCHEMES;
                                } else if ($route.current.params.tabName === 'global') {
                                    $route.current.$$route.title = 'timeoff.tabs.global';
                                    return TIMEOFF_SETTINGS_TABS.GLOBAL;
                                } else {
                                    $location.path('/not-found').replace();
                                }
                            } else {
                                $location.path('/not-found').replace();
                            }
                        }]
                    },
                    reloadOnUrl: false
                })
                .when('/settings/timeoff/scheme/:tabName/update/:id', {
                    templateUrl: './ng1/components/timeoff-settings-scheme/timeoff-settings-scheme.html',
                    module: MODULES.HOLIDAYS,
                    access: {
                        authorizedRoles: [USER_ROLES.humanResources, USER_ROLES.admin]
                    },
                    resolve: {
                        selectedTab: ['$location', '$route', 'TIMEOFF_SETTINGS_SCHEME_TABS', function($location, $route, TIMEOFF_SETTINGS_SCHEME_TABS) {
                            if ($route.current.params && $route.current.params.tabName) {
                                if ($route.current.params.tabName === 'general') {
                                    $route.current.$$route.title = 'schemes.tabs.general';
                                    return TIMEOFF_SETTINGS_SCHEME_TABS.GENERAL;
                                } else if ($route.current.params.tabName === 'absences') {
                                    $route.current.$$route.title = 'schemes.tabs.absences';
                                    return TIMEOFF_SETTINGS_SCHEME_TABS.ABSENCES;
                                } else if ($route.current.params.tabName === 'employees') {
                                    $route.current.$$route.title = 'schemes.tabs.employees';
                                    return TIMEOFF_SETTINGS_SCHEME_TABS.EMPLOYEES;
                                } else {
                                    $location.path('/not-found').replace();
                                }
                            } else {
                                $location.path('/not-found').replace();
                            }
                        }]
                    },
                    reloadOnUrl: false
                })
                .when('/settings/time-tracking/:tabName', {
                    templateUrl: './ng1/components/time-tracking/time-tracking.html',
                    title: 'timeTracking.tabs.schedules',
                    module: MODULES.TIME_TRACKING,
                    access: {
                        authorizedRoles: [USER_ROLES.admin, USER_ROLES.humanResources]
                    },
                    resolve: {
                        selectedTab: ['$location', '$route', 'TIME_TRACKING_TABS', function($location, $route, TIME_TRACKING_TABS) {
                            if ($route.current.params && $route.current.params.tabName) {
                                switch ($route.current.params.tabName) {
                                    case 'schedules':
                                        $route.current.$$route.title = 'timeTracking.tabs.schedules';
                                        return TIME_TRACKING_TABS.SCHEDULES;
                                    case 'global':
                                        $route.current.$$route.title = 'timeTracking.tabs.global';
                                        return TIME_TRACKING_TABS.GLOBAL;
                                    case 'notifications':    
                                        $route.current.$$route.title = 'timeTracking.tabs.notifications';
                                        return TIME_TRACKING_TABS.NOTIFICATIONS;
                                    default:
                                        $location.path('/not-found').replace();
                                        break;
                                }
                            } else {
                                $location.path('/not-found').replace();
                            }
                        }]
                    },
                    reloadOnUrl: false
                })
                .when('/settings/time-tracking/schedule/:tabName/update/:id', {
                    templateUrl: './ng1/components/schedule/schedule-update.html',
                    title: 'timeTracking.schedule.title',
                    access: {
                        authorizedRoles: [USER_ROLES.admin, USER_ROLES.humanResources]
                    },
                    resolve: {
                        selectedTab: ['$location', '$route', 'SCHEDULE_TABS', function($location, $route, SCHEDULE_TABS) {
                            if ($route.current.params && $route.current.params.tabName) {
                                if ($route.current.params.tabName === 'general') {
                                    return SCHEDULE_TABS.GENERAL;
                                } else if ($route.current.params.tabName === 'record-kinds') {
                                    return SCHEDULE_TABS.RECORD_KINDS;
                                } else if ($route.current.params.tabName === 'users') {
                                    return SCHEDULE_TABS.USERS;
                                } else {
                                    $location.path('/not-found').replace();
                                }
                            } else {
                                $location.path('/not-found').replace();
                            }
                        }]
                    },
                    reloadOnUrl: false
                })
                .when('/settings/active-features', {
                    templateUrl: './ng1/components/active-features/active-features.html',
                    controller: 'ActiveFeaturesController',
                    controllerAs: 'vm',
                    title: 'activeFeatures.title',
                    access: {
                        authorizedRoles: [USER_ROLES.admin]
                    }
                })
                .when('/settings/documents/:tabName', {
                    templateUrl: './ng1/components/document-settings/document-settings.html',
                    title: 'documents.settingsTitle',
                    access: {
                        authorizedRoles: [USER_ROLES.admin, USER_ROLES.documentsManager]
                    },
                    resolve: {
                        selectedTab: ['$location', '$route', 'DOCUMENT_TABS', function($location, $route, DOCUMENT_TABS) {
                            if ($route.current.params && $route.current.params.tabName) {
                                switch ($route.current.params.tabName) {
                                    case 'global':
                                        $route.current.$$route.title = 'documents.settingsTitle';
                                        return DOCUMENT_TABS.GLOBAL;
                                    case 'conventions':
                                        $route.current.$$route.title = 'documents.settingsTitle';
                                        return DOCUMENT_TABS.CONVENTIONS;
                                    default:
                                        $location.path('/not-found').replace();
                                        break;
                                }
                            } else {
                                $location.path('/not-found').replace();
                            }
                        }]
                    },
                    reloadOnUrl: false
                })
                .when('/settings/chatrooms', {
                    templateUrl: './ng1/components/chatrooms/chatrooms.html',
                    title: 'chatrooms.title',
                    access: {
                        authorizedRoles: [USER_ROLES.admin, USER_ROLES.chatroomManager]
                    }
                })
                .when('/settings/chatroom/:tabName/update/:id', {
                    templateUrl: './ng1/components/chatroom/chatroom-update.html',
                    title: 'chatrooms.update.title',
                    access: {
                        authorizedRoles: [USER_ROLES.admin, USER_ROLES.chatroomManager]
                    },
                    resolve: {
                        selectedTab: ['$location', '$route', 'CHATROOM_TABS', function($location, $route, CHATROOM_TABS) {
                            if ($route.current.params && $route.current.params.tabName) {
                                if ($route.current.params.tabName === 'general') {
                                    return CHATROOM_TABS.GENERAL;
                                } else if ($route.current.params.tabName === 'users') {
                                    return CHATROOM_TABS.USERS;
                                } else {
                                    $location.path('/not-found').replace();
                                }
                            } else {
                                $location.path('/not-found').replace();
                            }
                        }]
                    },
                    reloadOnUrl: false
                })
                .when('/forbidden', {
                    templateUrl: './ng1/components/error/forbidden.html',
                    title: '403.title',
                    access: {
                        authorizedRoles: [USER_ROLES.all]
                    }
                })
                .when('/not-found', {
                    templateUrl: './ng1/components/error/not-found.html',
                    title: '404.title',
                    access: {
                        authorizedRoles: [USER_ROLES.all]
                    }
                })
                .when('/server-error', {
                    templateUrl: './ng1/components/error/server-error.html',
                    controller: 'ServerErrorController',
                    title: '500.title',
                    access: {
                        authorizedRoles: [USER_ROLES.all]
                    }
                })
                .when('/browser-not-supported', {
                    templateUrl: './ng1/components/error/browser-not-supported.html',
                    title: 'browserSupport.nonSupported.viewTitle',
                    access: {
                        authorizedRoles: [USER_ROLES.all]
                    }
                })
                .when('/privacy-policy', {
                    templateUrl: './ng1/components/privacy-policy/privacy-policy.html',
                    title: 'global.privacyPolicy',
                    access: {
                        authorizedRoles: [USER_ROLES.all]
                    }
                })
                .when('/terms-and-conditions', {
                    templateUrl: './ng1/components/terms-and-conditions/terms-and-conditions.html',
                    title: 'global.termsAndConditions',
                    access: {
                        authorizedRoles: [USER_ROLES.all]
                    }
                })
                .when('/personal-data-treatment', {
                    templateUrl: './ng1/components/personal-data-treatment/personal-data-treatment.html',
                    title: 'global.personalDataTreatment',
                    access: {
                        authorizedRoles: [USER_ROLES.all]
                    }
                })
                .when('/privacy-policy-en', {
                    templateUrl: './ng1/components/privacy-policy/privacy-policy-en.html',
                    title: 'global.privacyPolicy',
                    access: {
                        authorizedRoles: [USER_ROLES.all]
                    }
                })
                .when('/terms-and-conditions-en', {
                    templateUrl: './ng1/components/terms-and-conditions/terms-and-conditions-en.html',
                    title: 'global.termsAndConditions',
                    access: {
                        authorizedRoles: [USER_ROLES.all]
                    }
                })
                .when('/personal-data-treatment-en', {
                    templateUrl: './ng1/components/personal-data-treatment/personal-data-treatment-en.html',
                    title: 'global.personalDataTreatment',
                    access: {
                        authorizedRoles: [USER_ROLES.all]
                    }
                })
                .when('/privacy-policy-fr', {
                    templateUrl: './ng1/components/privacy-policy/privacy-policy-fr.html',
                    title: 'global.privacyPolicy',
                    access: {
                        authorizedRoles: [USER_ROLES.all]
                    }
                })
                .when('/terms-and-conditions-fr', {
                    templateUrl: './ng1/components/terms-and-conditions/terms-and-conditions-fr.html',
                    title: 'global.termsAndConditions',
                    access: {
                        authorizedRoles: [USER_ROLES.all]
                    }
                })
                .when('/personal-data-treatment-fr', {
                    templateUrl: './ng1/components/personal-data-treatment/personal-data-treatment-fr.html',
                    title: 'global.personalDataTreatment',
                    access: {
                        authorizedRoles: [USER_ROLES.all]
                    }
                })
                .when('/problem-solving-notification', {
                    templateUrl: './ng1/components/problem-solving/problem-solving-notification.html',
                    title: 'global.problemSolving',
                    access: {
                        authorizedRoles: [USER_ROLES.all]
                    }
                })
                .when('/oauth-success', {
                    templateUrl: './ng1/components/oauth-success/oauth-success.html',
                    controller: 'OAuthSuccessController',
                    controllerAs: 'vm',
                    title: 'home.welcome.title',
                    access: {
                        authorizedRoles: [USER_ROLES.all]
                    },
                    resolve: {
                        authCode: ['$location', '$rootScope', 'authService', 'OAuthAuthenticationService', 'Session',
                            function($location, $rootScope, authService, OAuthAuthenticationService, Session) {

                            if ($rootScope.externalLoginFailed) {
                                Session.invalidate();
                                authService.loginCancelled();
                                return false;
                            }
                            return OAuthAuthenticationService.getLoginConfiguration().then(function (loginConfig) {
                                if (loginConfig) {
                                    var tokenName = loginConfig.redirectUriTokenName || 'id_token';
                                    var urlParams = $location.hash();
                                    var params = urlParams.split('&');
                                    if (params.length > 0 && params[0] !== '') {
                                        for (var index = 0; index < params.length; index++) {
                                            if (params[index].startsWith(tokenName)) {
                                                return params[index].replace(tokenName + '=', '');
                                            }
                                        }
                                    } else {
                                        var searchParams = $location.search();
                                        if (searchParams && searchParams[tokenName]) {
                                            return searchParams[tokenName];
                                        }
                                    }
                                }
                                Session.invalidate();
                                authService.loginCancelled();
                                return false;
                            });

                        }]
                    }
                })
                .when('/wkauth-success', {
                    templateUrl: './ng1/components/wkauth/wkauth-success.html',
                    controller: 'WKAuthSuccessController',
                    controllerAs: 'vm',
                    title: 'users.syncTooltip',
                    access: {
                        authorizedRoles: [USER_ROLES.all]
                    },
                    resolve: {
                        authCode: ['$location', function($location) {
                            var urlParams = $location.hash();
                            var params = urlParams.split('&');
                            if (params.length > 0) {
                                var firstParam = params[0].split('=');
                                if (firstParam[0] === 'code') {
                                    return firstParam[1];
                                }
                            }
                            return null;
                        }]
                    }
                })
                .when('/saml2-success', {
                    templateUrl: './ng1/components/saml2-success/saml2-success.html',
                    controller: 'Saml2SuccessController',
                    controllerAs: 'vm',
                    title: 'home.welcome.title',
                    access: {
                        authorizedRoles: [USER_ROLES.all]
                    }
                })
                .when('/install-via-testflight', {
                    templateUrl: './ng1/components/install-via-testflight/install-via-testflight.html',
                    title: 'testFlight.title',
                    controller: 'InstallViaTestflightController',
                    access: {
                        authorizedRoles: [USER_ROLES.all]
                    },
                    resolve: {
                        companyTestflightConfiguration: ['$q', '$route', 'CompaniesService', 'defaultLanguage',
                            function($q, $route, CompaniesService, defaultLanguage) {
                                if ($route.current.params && $route.current.params.c) {
                                    return CompaniesService.getCompanyTestflightConfiguration($route.current.params.c).then(function(companyTestflightConfiguration) {
                                        return $q.resolve({
                                            data: companyTestflightConfiguration.data,
                                            language: $route.current.params.lang || defaultLanguage
                                        });
                                    }, function(error) {
                                        return $q.reject();
                                    });
                                } else {
                                    return $q.reject();
                                }
                            }
                        ]
                    }
                })
                .when('/html-components', {
                    templateUrl: './ng1/components/html-components/html-components.html',
                    controller: 'HTMLComponentsController',
                    title: 'htmlComponents.title',
                    access: {
                        authorizedRoles: [USER_ROLES.all]
                    },
                    resolve: {
                        authorization: ['$q', 'SharedDataService', function($q, SharedDataService) {
                            if (SharedDataService.environmentName !== 'distribution') {
                                return $q.resolve();
                            } else {
                                return $q.reject();
                            }
                        }]
                    }
                })

            .otherwise('/not-found');

            $locationProvider.html5Mode(true).hashPrefix('');

            $httpProvider.defaults.withCredentials = true;
            $httpProvider.interceptors.push('ErrorInterceptor');
            $qProvider.errorOnUnhandledRejections(false);

            tmhDynamicLocaleProvider.localeLocationPattern('./ng1/libs/angular-i18n/angular-locale_{{locale}}.js?v=' + (+new Date).toString(36));
            tmhDynamicLocaleProvider.useCookieStorage('NG_TRANSLATE_LANG_KEY');

        }
    ]).run(['GlobalService', 'defaultLanguage', function(GlobalService, defaultLanguage) {

        GlobalService.initialize();

        GlobalService.initializeLanguage(defaultLanguage);

    }]);

function AuthorizationError(message, description) {
    this.message = message || "Forbidden";
    this.description = description || "User authentication required.";
}

AuthorizationError.prototype = Object.create(Error.prototype);
AuthorizationError.prototype.constructor = AuthorizationError;

function AlreadyLoggedError() {}
AlreadyLoggedError.prototype = Object.create(Error.prototype);
AlreadyLoggedError.prototype.constructor = AlreadyLoggedError;